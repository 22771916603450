export const myData = [
  {
    link: "/my-card",
    text: "Mi tarjeta",
  },
  {
    link: "/my-card",
    text: "Mis datos en EFPA España",
  },
  {
    link: "/my-card",
    text: "Mis datos en EFPA Europa",
  },
  {
    link: "/my-card",
    text: "Modificar mis datos de acceso",
  },
  {
    link: "/my-card",
    text: "Autorizaciones y permisos",
  },
  {
    link: "/my-card",
    text: "Avisos recibidos",
  },
]
