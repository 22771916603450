import React from "react";

const Finectlogo = ({ color, className }) => (
    <svg className={className} version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 496 496" enable-background="new 0 0 496 496">
<path fill={color} opacity="1.000000" stroke="none" 
	d="
M270.699219,98.699135 
	C275.195435,94.214928 279.440796,89.979843 283.682983,85.741570 
	C287.422852,82.005188 291.869171,80.415169 296.879791,82.409431 
	C302.120270,84.495186 304.252228,88.981834 304.260071,94.384308 
	C304.311920,130.034225 304.265015,165.684280 304.320343,201.334183 
	C304.327454,205.912140 302.072266,209.218201 299.062317,212.238327 
	C285.424622,225.922089 271.873291,239.694412 258.059875,253.198547 
	C255.162689,256.030853 255.216248,257.643799 257.980743,260.364746 
	C271.514709,273.685577 284.823517,287.235168 298.362518,300.550781 
	C302.428009,304.549164 304.330933,308.980560 304.307312,314.741150 
	C304.167389,348.891266 304.209991,383.042297 304.274872,417.192871 
	C304.286133,423.121002 302.947052,428.486786 297.055603,430.780029 
	C291.332886,433.007599 286.573364,430.519775 282.302216,426.240387 
	C247.116257,390.987030 211.841766,355.821808 176.525345,320.699036 
	C173.048447,317.241180 171.794693,313.129700 171.787582,308.519989 
	C171.733887,273.703003 171.736282,238.885941 171.739380,204.068909 
	C171.739746,199.905441 173.081802,196.265213 176.088257,193.263504 
	C201.311401,168.080124 226.518890,142.881058 251.728546,117.684174 
	C257.972748,111.443138 264.207733,105.192879 270.699219,98.699135 
M268.225952,133.725922 
	C245.557205,156.402512 222.888458,179.079117 200.251297,201.724106 
	C201.051590,202.950409 201.325943,203.578278 201.776459,204.030762 
	C212.930756,215.234039 224.142685,226.380295 235.240189,237.639389 
	C237.445145,239.876434 238.957947,239.515701 240.995255,237.459778 
	C253.410583,224.931076 265.883087,212.458466 278.419220,200.050674 
	C280.402832,198.087387 280.851227,195.894714 280.846985,193.293976 
	C280.810242,170.826965 280.845062,148.359818 280.799469,125.892838 
	C280.797150,124.749062 281.536835,123.218719 279.518127,122.306221 
	C275.954865,125.913910 272.342163,129.571671 268.225952,133.725922 
M256.509125,367.990875 
	C264.105682,375.817810 271.702240,383.644714 280.057434,392.253296 
	C280.549042,390.055817 280.800415,389.455048 280.800995,388.854034 
	C280.823273,365.528351 280.803528,342.202606 280.853973,318.876984 
	C280.859131,316.493103 279.859589,314.808807 278.246124,313.205170 
	C265.956390,300.990204 253.715622,288.725952 241.431503,276.505280 
	C240.405945,275.485046 239.516647,274.138367 237.311783,273.644989 
	C225.948822,284.648743 215.043762,296.095276 203.828522,307.229309 
	C200.504456,310.529297 200.820450,312.473694 204.002029,315.607056 
	C221.448593,332.789001 238.689499,350.179810 256.509125,367.990875 
M195.283829,240.619080 
	C195.283829,254.296555 195.283829,267.974030 195.283829,283.161835 
	C204.105759,274.318909 211.712433,266.678284 219.340408,259.059021 
	C221.242752,257.158844 220.588898,255.592987 218.932785,253.959518 
	C214.312469,249.402359 209.852051,244.677017 205.113800,240.247787 
	C202.172455,237.498230 199.819366,234.014191 195.286072,231.560074 
	C195.286072,234.783356 195.286072,237.226105 195.283829,240.619080 
z"/>
<path fill={color} opacity="1.000000" stroke="none" 
	d="
M318.825775,237.906174 
	C333.727417,239.579529 338.691376,253.699219 334.789795,262.614075 
	C331.342224,270.491577 323.783722,274.843567 315.047119,273.394318 
	C306.593414,271.991943 300.864532,265.508820 300.498413,256.930115 
	C300.110748,247.846695 305.039764,240.896759 313.510681,238.695679 
	C315.108093,238.280624 316.775513,238.135117 318.825775,237.906174 
z"/>
</svg>
   
);

export default Finectlogo;
