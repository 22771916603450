import { useEffect, useRef } from "react"
import DataCollect from "../data/data-collect"

function useDataCollect() {
  const prevLocation = useRef()
  const userpath = typeof window !== "undefined" ? window.location.pathname : ""

  useEffect(() => {
    if (userpath && userpath !== prevLocation.current) {
      prevLocation.current = userpath
      const location = {
        pathname: userpath,
        search: window.location.search,
      }
      DataCollect.send(location)
    }
  }, [userpath])
}

export default useDataCollect
