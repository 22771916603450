import React, { Fragment } from "react"
import { Link } from "gatsby"
import NavbarOptions from "./NavbarOptions"
import NavbarMobile from "./NavbarMobile"
import { options, navbar } from "../../data/navbar-options"

import { myData } from "../../data/navbar-mobile"

export default function Navbar(props) {
  const { openMenu, useOptions, className } = props

  const renderOptions = () => {
    return (
      options &&
      options.map((item, index) => {
        return (
          <li className="Navbar-item" key={`navbar-${index}`}>
            {renderItems(item)}
          </li>
        )
      })
    )
  }

  const renderItems = item => {
    if (item.item === "Revista")
      return (
        <a
          className="Navbar-link"
          href={item.link}
          target="_blank"
          rel="noreferrer"
        >
          {item.item}
        </a>
      )
    if (!item?.option) {
      return (
        <Link to={item.link} className="Navbar-link">
          {item.item}
        </Link>
      )
    }

    return (
      <Fragment>
        <i className="fas fa-caret-down"></i>
        <Link to={item.link} className="Navbar-link">
          {item.item}
        </Link>
        <NavbarOptions list={item.option} />
      </Fragment>
    )
  }

  const renderMobileOptions = () => {
    if (!useOptions) {
      return (
        navbar &&
        navbar.map((item, index) => {
          return (
            <NavbarMobile
              noOption
              title={item.title}
              link={item.link}
              key={`mobila-option-${index}`}
            />
          )
        })
      )
    }
    return (
      options &&
      options.map((item, index) => {
        return (
          <NavbarMobile
            title={item.item}
            links={item.option}
            link={item.link}
            noOption={!!item.link}
            key={`navbar-mobile-${index}`}
          />
        )
      })
    )
  }

  const renderData = () => {
    if (!useOptions) {
      return <Fragment></Fragment>
    }
    return (
      <Fragment>
        <NavbarMobile title="Mis datos" links={myData} />
        <div className="Navbar-options">
          <span className="Navbar-logout">Cerrar sesión</span>
        </div>
      </Fragment>
    )
  }

  return (
    <div className={`Navbar ${className}`}>
      <div className="Navbar-container">
        <div className="Navbar-menu menu-intranet d-none d-lg-block">
          <ul className="Navbar-list">{renderOptions()}</ul>
        </div>
        <div
          className={
            openMenu
              ? "Navbar-menu menu-intranet d-block"
              : "Navbar-menu menu-intranet d-none"
          }
        >
          <ul className="Navbar-list">
            {renderMobileOptions()}
            {renderData()}
          </ul>
        </div>
        <form className="Navbar-search" action="GET">
          <i class="fas fa-search"></i>
          <input className="Navbar-input" type="text" placeholder="Buscador" />
        </form>
      </div>
    </div>
  )
}
