export const footerItemsSecondary = [
  {
    title: "Actualidad",
    items: [
      { text: "Noticias de EFPA", link: "/actualidad/noticias-efpa" },
      { text: "Entrevistas", link: "/actualidad/entrevistas" },
      {
        text: "Noticias de asesoramiento financiero",
        link: "/actualidad/noticias",
      },
      {
        text: "Noticias de asociados",
        link: "/actualidad/noticias-de-asociados",
      },
      { text: "Artículos", link: "/actualidad/articulos" },
      { text: "Sala de prensa", link: "/sala-de-prensa" },
      { text: "Galerías de imágenes", link: "/actualidad/galerias" },
    ],
  },
  {
    title: "Servicios y actividades",
    items: [
      { text: "Servicios", link: "/servicios-y-actividades" },
      { text: "Exámenes", link: "/servicios-y-actividades/examenes-web" },
      { text: "Conferencias EFPA", link: "/" },
      { text: "#JornadasEFPA", link: "/" },
      { text: "EFPA Congress", link: "/" },
      { text: "Conferencias externas", link: "/" },
      { text: "Ferias", link: "/" },
      { text: "Otros actos", link: "/" },
    ],
  },
  {
    title: "Contacte con EFPA España",
    className: "italic",
    items: [
      { text: "asociados@efpa.es", link: "/", className: "italic" },
      { text: "Tel: +34934121008", link: "/", className: "italic" },
      { text: "Fax: +34 93 318 71 01", link: "/", className: "italic" },
      { text: "Skype: efpa_espa", link: "/", className: "italic" },
    ],
  },
  {
    items: [
      { text: "Preguntas Frecuentes", link: "/preguntas-frecuentes", className: "bold" },
      { text: "Imagen corporativa EFPA", link: "/", className: "bold" },
      { text: "Aviso legal", link: "/", className: "bold" },
      { text: "Política de cookies", link: "/", className: "bold" },
      { text: "Política de privacidad", link: "/", className: "bold" },
    ],
  },
]
