import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export default function NavbarOptions(props) {
  const { list } = props
  const renderOptions = () => {
    return (
      list &&
      list.map(item => {
        return (
          <li className={`NavbarOptions-item ${item.className}`}>
            <Link className="NavbarOptions-link" to={item.link}>
              {item.text}
            </Link>
          </li>
        )
      })
    )
  }

  return <ul className="NavbarOptions-list">{renderOptions()}</ul>
}

NavbarOptions.propTypes = {
  list: PropTypes.array,
}

NavbarOptions.defaultProps = {
  list: [],
}
