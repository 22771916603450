export const options = [
  {
    item: "Quiénes somos",
    option: [
      {
        link: "/asociacion",
        text: "Definición, Misión y Visión",
      },
      {
        link: "/efpa-europa",
        text: "EFPA Europa",
      },
      {
        link: "/estructura-organizativa",
        text: "Estructura organizativa",
      },
      {
        link: "/entidades-colaboradoras",
        text: "Entidades colaboradoras",
      },
    ],
  },
  {
    item: "Certificaciones",
    option: [
      {
        link: "/certificacion",
        text: "Definición ",
      },
      {
        link: "/centros-formativos-acreditados",
        text: "Cursos preparatorios para los exámenes",
        className: "big",
      },
      {
        link: "/fechas-examenes",
        text: "Próximos exámenes",
      },
      {
        link: "/reconocimientos-otras-certificaciones",
        text: "Reconocimientos",
      },
      {
        link: "/como-hacerme-asociado-efpa",
        text: "Asociarme a EFPA España",
      },
    ],
  },
  {
    item: "Servicios y actividades",
    link: "/servicios-y-actividades",
  },
  {
    item: "Actualidad",
    link: "/actualidad",
  },
  {
    item: "Canal EFPA",
    link: "/actualidad/videos",
  },
  {
    item: "Revista",
    link: "https://www.asesoresfinancierosefpa.es/",
  },
  {
    item: "Contacto",
    option: [
      {
        link: "/donde-estamos",
        text: "Dónde estamos",
      },
      {
        link: "/contacto",
        text: "Contáctenos ",
      },
      {
        link: "/a-quien-dirigirme",
        text: "¿A quién dirigirme?",
      },
      {
        link: "/preguntas-frecuentes",
        text: "Preguntas frecuentes ",
      },
      {
        link: "/sala-de-prensa",
        text: " Prensa (actual Sala de prensa) ",
      },
    ],
  },
]

export const navbar = [
  { title: "Quiénes somos", link: "/asosiacion" },
  { title: "Certificaciones", link: "/certificacion" },
  { title: "Servicios y actividades", link: "/servicios-y-actividades" },
  { title: "Canal EFPA", link: "/canal-efpa" },
  { title: "Actualidad", link: "/actualidad" },
  { title: "Revista", link: "/" },
  { title: "Contacto", link: "/contacto" },
]
