import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export default function NavbarMobile(props) {
  const { title, links, noOption, link } = props

  const [showLink, setShowLink] = useState(false)
  const ShowOptionSubMenu = () => setShowLink(!showLink)

  const renderLinks = () => {
    return (
      links &&
      links.map(item => {
        return (
          <Link to={item.link} className="Navbar-option">
            {item.text}
          </Link>
        )
      })
    )
  }

  const renderOptions = () => {
    if (!noOption) {
      return (
        <div className={showLink ? "Navbar-links" : "d-none"}>
          {renderLinks()}
        </div>
      )
    }
    return <Fragment></Fragment>
  }

  const renderTitle = () => {
    if (!noOption) {
      return (
        <button
          className={showLink ? "Navbar-title active" : "Navbar-title"}
          onClick={ShowOptionSubMenu}
        >
          {title}
        </button>
      )
    }
    return (
      <Link to={link} className="Navbar-title">
        {title}
      </Link>
    )
  }

  return (
    <div className="Navbar-options">
      {renderTitle()}
      {renderOptions()}
    </div>
  )
}

NavbarMobile.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
}

NavbarMobile.defaultProps = {
  title: "",
  links: [],
}
