import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useAxiosClient } from "../../../service/useAxiosClient"

export default function Footer() {
  const [menuList, setMenuList] = useState([])
  const [activitiesCategories, setActivitiesCategories] = useState([])

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })

  useEffect(() => {
    if (dataMenu && dataMenu.length) {
      setMenuList(dataMenu)
      console.log(`dataMenu`, dataMenu)
    }
  }, [dataMenu])

  const [{ data: activitiesCategoriesData }] = useAxiosClient({
    url: `/activities/categories/web/`,
    method: "GET",
  })

  useEffect(() => {
    if (activitiesCategoriesData && activitiesCategoriesData.length) {
      setActivitiesCategories(activitiesCategoriesData)
      console.log(`activitiesCategoriesData`, activitiesCategoriesData)
    }
  }, [activitiesCategoriesData])

  const renderTopicality = () => {
    return (
      Array.isArray(menuList) &&
      menuList.map(item => {
        return (
          <li className="Footer-item">
            <Link
              to={`/actualidad/${item.pk}/${item.slug}`}
              className="Footer-link"
            >
              {item.title}
            </Link>
          </li>
        )
      })
    )
  }

  const renderCategories = () => {
    return (
      Array.isArray(activitiesCategories) &&
      activitiesCategories.map(item => {
        return (
          <li className="Footer-item">
            <Link
              to={`/servicios-y-actividades/${item.pk}/${item.slug}`}
              className="Footer-link"
            >
              {item.title}
            </Link>
          </li>
        )
      })
    )
  }

  return (
    <footer className="Footer">
      <div className="Footer-container">
        <div className="row efpa-row">
          <div className="col-6 col-sm-3">
            <p className="Footer-title">Actualidad</p>
            <ul className="Footer-list">
              {renderTopicality()}
              <li className="Footer-item">
                <Link to={`/sala-de-prensa`} className="Footer-link">
                  Sala de prensa
                </Link>
              </li>
              <li className="Footer-item">
                <Link to={`/actualidad/videos`} className="Footer-link">
                  Videos
                </Link>
              </li>
              <li className="Footer-item">
                <Link to={`/actualidad/galerias`} className="Footer-link">
                  Galerías de imágenes
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-3">
            <p className="Footer-title">Servicios y actividades</p>
            <ul className="Footer-list">{renderCategories()}</ul>
          </div>
          <div className="col-6 col-sm-3">
            <p className="Footer-title">Contacte con EFPA España</p>
            <ul className="Footer-list">
              <li className="Footer-item">
                <a href="mailto:informacion@efpa.es" className="Footer-link">
                  informacion@efpa.es
                </a>
              </li>
              <li className="Footer-item">
                <a className="Footer-link" href="tel:+34934121008">
                  Tel: +34934121008
                </a>
              </li>
              <li className="Footer-item">
                <p className="Footer-link mb-0" href="#">
                  Skype: efpa_espa
                </p>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-3">
            <ul className="Footer-list">
              <li className="Footer-item">
                <Link
                  to="/preguntas-frecuentes"
                  className="Footer-link withoutDot"
                >
                  <p className="Footer-title">Preguntas Frecuentes</p>
                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/aviso-legal" className="Footer-link withoutDot">
                  <p className="Footer-title">Aviso legal</p>
                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/politica-cookies" className="Footer-link withoutDot">
                  <p className="Footer-title">Política de cookies</p>
                </Link>
              </li>
              <li className="Footer-item">
                <Link
                  to="/politica-privacidad"
                  className="Footer-link withoutDot"
                >
                  <p className="Footer-title">Política de privacidad</p>
                </Link>
              </li>
              <li className="Footer-item">
                <Link
                  to="/politica-de-calidad"
                  className="Footer-link withoutDot"
                >
                  <p className="Footer-title">Política de calidad</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
