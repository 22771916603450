import React from "react";

const PodcastIcon = ({ color, className }) => (
    <svg className={className} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
   fill={color} stroke="none">
   <path d="M2260 4919 c-127 -13 -319 -52 -462 -94 -389 -115 -754 -337 -1043
   -636 -593 -613 -818 -1472 -604 -2306 118 -460 411 -927 770 -1224 254 -211
   509 -355 800 -453 222 -75 490 -126 659 -126 l50 0 0 185 c0 102 -3 185 -6
   185 -24 0 -164 25 -227 41 -370 93 -694 397 -816 765 -52 156 -62 256 -59 559
   l3 270 70 0 70 0 6 -315 c6 -300 7 -319 32 -407 104 -366 377 -635 742 -733
   103 -27 284 -37 396 -21 419 62 753 363 861 776 18 70 22 120 27 390 l6 310
   70 0 70 0 0 -300 c0 -216 -4 -320 -14 -370 -73 -365 -307 -673 -641 -842 -107
   -54 -250 -96 -402 -118 l-48 -6 0 -185 0 -184 53 0 c147 0 430 52 622 115 373
   121 690 317 970 599 207 209 340 393 460 641 109 225 184 461 220 695 23 143
   31 467 15 616 -56 557 -307 1083 -705 1479 -364 362 -844 601 -1365 680 -128
   19 -451 27 -580 14z m401 -723 c164 -35 310 -117 428 -241 85 -90 140 -181
   183 -305 l33 -95 3 -652 3 -653 -811 0 -811 0 3 653 3 652 33 95 c43 124 98
   215 185 306 115 122 260 204 422 239 80 18 244 18 326 1z m649 -2383 c0 -201
   -4 -297 -14 -345 -62 -291 -282 -528 -576 -620 -64 -20 -96 -23 -220 -23 -124
   0 -156 3 -220 23 -265 84 -463 274 -551 528 l-34 99 -3 308 -4 307 811 0 811
   0 0 -277z"/>
   </g>
   </svg>
   
);

export default PodcastIcon;
